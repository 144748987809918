import React from "react";
import { Alert } from "../components/IconSystem";
import { Box, Flex, Text, MaxWidth, Inline } from "@sqymagma/elements";
import ModAnimation from "../components/Chronology/Modules/ModAnimation";
import ModDottedLine from "../components/Chronology/Modules/ModDottedLine";
import { MenuOpen } from "../components/IconSystem";
import { Link } from "gatsby";
import SEO from "../components/Seo";

const NotFoundPage = () => {
  const symptonsList = [
    "tos",
    "fiebre alta",
    "cansancio",
    "dificultad para respirar (casos severos)",
  ];
  return (
    <>
      <SEO
        title={'Not found'}
      />
      <Box bg="ui08" color="text05" minHeight="100vh" pt="m" pb="xl">
        <MaxWidth>
          <Inline>
            <Alert fill="brand04" width="24px" mr="8px" />
            <Text textStyle="m">Page not found. ERROR - 404</Text>
          </Inline>
          <Flex
            flexWrap="wrap"
            flexDirection={["column", null, "row"]}
            width="85%"
            alignSelf={["flex-end", null, "center"]}
          >
            <Flex
              width={["100%", null, "40%"]}
              alignItems="center"
              justifyContent="center"
            >
              <Box width={["50%", null, "100%"]} my="-24px">
                <ModAnimation svg="/images/anim/logo_ani_white.svg" />
              </Box>
            </Flex>
            <Box width={["100%", null, "60%"]}>
              <Text as="h1" color="brand03" textStyle="xxxl">
                La experiencia Covid
              </Text>
              {symptonsList && (
                <Text as="ul" mb="xl">
                  <ModDottedLine color="ui05" />
                  {symptonsList.map((item) => (
                    <Text
                      as="li"
                      color="text06"
                      textStyle="xl-semi"
                      ml="m"
                      css={`
                         {
                          &:before {
                            content: "·";
                            margin-right: 8px;
                          }
                        }
                      `}
                    >
                      {item}
                    </Text>
                  ))}
                  <ModDottedLine color="ui05" />
                </Text>
              )}
              <Text as="p" color="text06" textStyle="xxxl" mb="s">
                ¿Tienes los síntomas?
              </Text>
              <Text as="h2" color="brand04" textStyle="tera" caps mb="l">
                Pillado!
              </Text>
              <Box>
                <Box borderBottom="3px solid #fff" width={1 / 3} mb="l" />
                <Text
                  as="p"
                  color="text06"
                  fontSize={["24px", null, "32px"]}
                  fontWeight="800"
                  lineHeight="1.1"
                >
                  #QuédateEnCasa,
                </Text>
                <Text
                  as="p"
                  color="brand03"
                  fontSize={["24px", null, "32px"]}
                  fontWeight="800"
                  lineHeight="1.1"
                >
                  hazte el test – sin excusas
                </Text>
              </Box>
            </Box>
          </Flex>
          <Text
            color="text03"
            position="absolute"
            right="40px"
            top="50vh"
            display={["none", null, "flex"]}
            css={`
               {
                transform: rotate(-90deg) translateX(50%);
                transform-origin: right;
              }
            `}
          >
            Error – 404
          </Text>

          <Flex
            alignItems="center"
            color="text03"
            position="absolute"
            left="24px"
            top="65vh"
            css={`
               {
                transform: rotate(-90deg) translateX(-50%);
                transform-origin: left;
              }
            `}
          >
            <Text>This is your only scape</Text>
            <Box
              width="55px"
              height="70px"
              ml="l"
              css={`
                 {
                  transform: rotate(90deg);
                }
              `}
            >
              <ModAnimation svg="/images/anim/arrow-animated.svg" />
            </Box>
          </Flex>
          <Box
            top="50vh"
            left="0"
            position="absolute"
            bg="ui01"
            height="48px"
            width="48px"
            p="xs"
            css="{
                        cursor:pointer;
                        transition: opacity ease .3s;
                        transform: translateY(-50%);

                        &:hover {
                            opacity: .85;
                        }
                    }"
          >
            <Link to="/">
              <MenuOpen fill="text01" mt="2px" />
            </Link>
          </Box>
        </MaxWidth>
      </Box>
    </>
  );
};


export default NotFoundPage;
