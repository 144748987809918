import React from "react"
import { Box } from '@sqymagma/elements'


export default({svg, alt="animación isométrica", width="100%"}) => {
  return (
    <Box width={width} textAlign="center" my="m">
      <object type="image/svg+xml" data={svg} aria-label={alt} alt={alt} />
    </Box>
  )
}